// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Stack from "@mui/material/Stack";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKBadge from "components/MKBadge";
import MKTypography from "components/MKTypography";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function ServicesList() {
  return (
    <MKBox component="section" py={{ xs: 3, md: 12 }}>
      <Container>
        <MKBadge
          variant="contained"
          color="primary"
          badgeContent="Servicios"
          container
          sx={{ mb: 2 }}
        />
        <Grid container alignItems="center">
          <Grid item xs={12} lg={5}>
            <MKTypography variant="h3" my={1}>
              Tu bienestar es nuestra prioridad.
            </MKTypography>
            <MKTypography variant="body2" color="text" mb={2}>
              Cada servicio está diseñado para ofrecerte una experiencia única y personalizada que te hará sentir renovado y rejuvenecido.
            </MKTypography>
          </Grid>
          <Grid item xs={12} lg={6} sx={{ ml: { xs: -2, lg: "auto" }, mt: { xs: 6, lg: 0 } }}>
            <Stack>


              <MKBox display="flex" alignItems="center" p={2} width="100%">
                <Accordion sx={{ width: "100%" }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <MKBox display="flex" alignItems="center">
                      <Icon fontSize="small" sx={{ mr: 1 }}>face_retouching_natural</Icon>
                      <MKTypography variant="h6" color="text" pl={2}>
                        Tratamientos Faciales
                      </MKTypography>
                    </MKBox>
                  </AccordionSummary>
                  <AccordionDetails>
                    <MKTypography variant="body2" color="text" pl={2}>
                      <ul style={{ listStyleType: "none", paddingLeft: 0 }}>
                        <li style={{ marginBottom: '8px', width: '100%' }}>Limpieza facial profunda</li>
                        <li style={{ marginBottom: '8px', width: '100%' }}>Botox</li>
                        <li style={{ marginBottom: '8px', width: '100%' }}>Dermaplaning</li>
                        <li style={{ marginBottom: '8px', width: '100%' }}>Radiofrecuencia facial</li>
                        <li style={{ marginBottom: '8px', width: '100%' }}>Luz pulsada para acné</li>
                        <li style={{ marginBottom: '8px', width: '100%' }}>Plasma rico para rejuvenecimiento facial</li>
                        <li style={{ marginBottom: '8px', width: '100%' }}>Peeling químico para rejuvenecimiento</li>
                        <li style={{ marginBottom: '8px', width: '100%' }}>Peeling químico despigmentante</li>
                        <li style={{ marginBottom: '8px', width: '100%' }}>Facial hidratante</li>
                        <li style={{ marginBottom: '8px', width: '100%' }}>Plasma rico en plaquetas</li>
                        <li style={{ marginBottom: '8px', width: '100%' }}>Tratamiento antiacné</li>
                        <li style={{ marginBottom: '8px', width: '100%' }}>Paquete rejuvenecimiento</li>
                        <li style={{ marginBottom: '8px', width: '100%' }}>Carboxiterapia facial</li>
                        <li style={{ marginBottom: '8px', width: '100%' }}>Tratamiento para cicatrices de acné</li>
                      </ul>
                    </MKTypography>
                  </AccordionDetails>
                </Accordion>
              </MKBox>

              <MKBox display="flex" alignItems="center" p={2} width="100%">
                <Accordion sx={{ width: "100%" }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <MKBox display="flex" alignItems="center">
                    <Icon fontSize="small" sx={{ mr: 1 }}>self_improvement</Icon>
                      <MKTypography variant="h6" color="text" pl={2}>
                        Tratamientos Corporales
                      </MKTypography>
                    </MKBox>
                  </AccordionSummary>
                  <AccordionDetails>
                    <MKTypography variant="body2" color="text" pl={2}>
                      <ul style={{ listStyleType: "none", paddingLeft: 0 }}>
                        <li style={{ marginBottom: '8px', width: '100%' }}>Tratamiento capilar (anti caída)</li>
                        <li style={{ marginBottom: '8px', width: '100%' }}>Masaje relajante</li>
                        <li style={{ marginBottom: '8px', width: '100%' }}>Exfoliación de espalda</li>
                        <li style={{ marginBottom: '8px', width: '100%' }}>Carboxiterapia</li>
                        <li style={{ marginBottom: '8px', width: '100%' }}>Moldeamiento corporal</li>
                        <li style={{ marginBottom: '8px', width: '100%' }}>Cavitación</li>
                      </ul>
                    </MKTypography>
                  </AccordionDetails>
                </Accordion>
              </MKBox>

              <MKBox display="flex" alignItems="center" p={2} width="100%">
                <Accordion sx={{ width: "100%" }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3a-content"
                    id="panel3a-header"
                  >
                    <MKBox display="flex" alignItems="center">
                    <Icon fontSize="small" sx={{ mr: 1 }}>medical_services</Icon>
                      <MKTypography variant="h6" color="text" pl={2}>
                        Tratamientos Especializados
                      </MKTypography>
                    </MKBox>
                  </AccordionSummary>
                  <AccordionDetails>
                    <MKTypography variant="body2" color="text" pl={2}>
                      <ul style={{ listStyleType: "none", paddingLeft: 0 }}>
                        <li style={{ marginBottom: '8px', width: '100%' }}>Eliminación de verrugas</li>
                        <li style={{ marginBottom: '8px', width: '100%' }}>Depilación IPL</li>
                        <li style={{ marginBottom: '8px', width: '100%' }}>Hidratación de labios</li>
                        <li style={{ marginBottom: '8px', width: '100%' }}>Eliminación de manchas con plasmapen</li>
                        <li style={{ marginBottom: '8px', width: '100%' }}>Rinomodelación</li>
                        <li style={{ marginBottom: '8px', width: '100%' }}>Eliminación de papada</li>
                        <li style={{ marginBottom: '8px', width: '100%' }}>Colocación de Hilos PDO o hilos tensores</li>
                      </ul>
                    </MKTypography>
                  </AccordionDetails>
                </Accordion>
              </MKBox>

              <MKBox display="flex" alignItems="center" p={2} width="100%">
                <Accordion sx={{ width: "100%" }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel4a-content"
                    id="panel4a-header"
                  >
                    <MKBox display="flex" alignItems="center">
                    <Icon fontSize="small" sx={{ mr: 1 }}>volunteer_activism</Icon>

                      <MKTypography variant="h6" color="text" pl={2}>
                        Bienestar y Orientación
                      </MKTypography>
                    </MKBox>
                  </AccordionSummary>
                  <AccordionDetails>
                    <MKTypography variant="body2" color="text" pl={2}>
                      <ul style={{ listStyleType: "none", paddingLeft: 0 }}>
                        <li style={{ marginBottom: '8px', width: '100%' }}>Orientación de vida saludable</li>
                        <li style={{ marginBottom: '8px', width: '100%' }}>Orientación de lactancia materna y cuidado del recién nacido</li>
                        <li style={{ marginBottom: '8px', width: '100%' }}>Colocación de suero vitaminado</li>
                        <li style={{ marginBottom: '8px', width: '100%' }}>Aplicación de inyecciones</li>
                        <li style={{ marginBottom: '8px', width: '100%' }}>Aplicación de medicamentos IV</li>
                        <li style={{ marginBottom: '8px', width: '100%' }}>Aplicación de suero</li>
                      </ul>
                    </MKTypography>
                  </AccordionDetails>
                </Accordion>
              </MKBox>



            </Stack>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default ServicesList;